.oxshadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-button {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(189, 189, 189, 0.63);
  border-radius: 5px;
}

.frame{
  aspect-ratio: 16 / 25; 
}